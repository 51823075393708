import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import Form from 'react-bootstrap/Form';

function RequestShortUrlForm(props) {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const customizeurlEndpoint = 'https://us-central1-myredirect-b32e0.cloudfunctions.net/customizeurl';
    const response1 = await fetch(customizeurlEndpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      },
      body: JSON.stringify({
        target: inputs.targetUrl,
        label: inputs.label,
        uid: inputs.username,
      })
    });
    const responseJson = await response1.json();
    props.setShortUrl(responseJson.customizedUrl);
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>Enter your username (e.g., travis_wang) :
          <input
            type="text"
            name="username"
            value={inputs.username || ""}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>Enter a label (e.g., "202_union_city_house_for_wechat_share"):
          <input
            type="text"
            name="label"
            value={inputs.label || ""}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>Enter destination URL (required):
          <input
            type="text"
            name="targetUrl"
            value={inputs.targetUrl || ""}
            onChange={handleChange}
          />
        </label>

        <br />
        <input type="submit" value="Generate your customized URL" />
      </form>
    </div>
  )
}

function UrlGeneration() {
  const [shortUrl, setShortUrl] = useState("");
  return (
    <div>
      <RequestShortUrlForm setShortUrl={setShortUrl} />
      <p>Your customized url is below:</p>
      <p>
        <div>
          {shortUrl}
          {'     '}
          {shortUrl.length > 0 && <button
            onClick={() => navigator.clipboard.writeText(shortUrl)}
          >
            Copy to clipboard
          </button>
          }
        </div>
      </p>
    </div>
  );
}

function RequestFilteredLogForm(props) {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestFilteredLogEndpoint = 'https://us-central1-myredirect-b32e0.cloudfunctions.net/fetchFilteredLog';
    const response = await fetch(requestFilteredLogEndpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      },
      body: JSON.stringify({
        shortUrl: inputs.shortUrl,
        target: inputs.targetUrl,
        label: inputs.label,
        uid: inputs.username,
        startTime: inputs.startTime,
        endTime: inputs.endTime,
      })
    });
    // const responseJson = await response1.json();
    // props.setShortUrl(responseJson.customizedUrl);
    const body = await response.json();
    let itemsFetched = (body.logFetched)[0];
    console.log(itemsFetched);

    let tempLogArray = [];
    for (let i = 0; i < itemsFetched.length; i++) {

      let item = itemsFetched[i];
      let payload = item.textPayload;

      if (payload !== undefined) {
        var date = new Date(item.timestamp.seconds * 1000);
        payload += (", event_time = " + date.toLocaleString());
        // const ip = "76.126.67.42";
        // const address = await iPToLocation(ip);
        // payload += (", address: " + address);
        tempLogArray.push(payload);
      }
    }
    props.setLog(tempLogArray);
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* <label>Enter your customized url:
          <input
            type="text"
            name="shortUrl"
            value={inputs.shortUrl || ""}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>Enter your username (required):
          <input
            type="text"
            name="username"
            value={inputs.username || ""}
            onChange={handleChange}
          />
        </label> */}
        <label>Enter your username (required):
          <input
            type="text"
            name="username"
            value={inputs.username || ""}
            onChange={handleChange}
          />
        </label>
        <br />
        {/* <label>Enter your customized URL (e.g. https://tinyurl.com/2py7qs6h)(required):
          <input
            type="text"
            name="targetUrl"
            value={inputs.targetUrl || ""}
            onChange={handleChange}
          />
        </label> */}
        {/* <br /> */}
        {/* <label>Enter the label for log:
          <input
            type="text"
            name="label"
            value={inputs.label || ""}
            onChange={handleChange}
          />
        </label> */}
        <label>Enter the label(required):
          <input
            type="text"
            name="label"
            value={inputs.label || ""}
            onChange={handleChange}
          />
        </label>
        {<br />}
        <label>Enter start time (optional):
          <input
            type="text"
            name="startTime"
            value={inputs.startTime || ""}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>Enter end time (optional):
          <input
            type="text"
            name="endTime"
            value={inputs.endTime || ""}
            onChange={handleChange}
          />
        </label>
        <br />
        <input type="submit" value="Show click history" />
      </form>
    </div>
  )
}

function getIpFromLog(log) {
  // let log = "uid = Wcf, label = test, target = https://tinyurl.com/e4rmk,clientIpAddress = 2601:646:ca80:4cf0:c01d:89e:1ac:258c END, event_time = 8/6/2022, 11:18:34 PM";
  return log.split(",").filter((str) => str.startsWith("clientIpAddress"))[0].split(" ")[2];
}

function FilteredLogSection() {
  const [log, setLog] = useState([]);
  return (
    <div>
      <RequestFilteredLogForm setLog={setLog} />
      <div className="center-col">
        <ul>
          {log.map((item) => <div>
            <p>{item}</p>
            <ShowLocation ip={getIpFromLog(item)} />
            <br />
            <br />
          </div>)}
        </ul>
      </div>
    </div>
  );
}

async function iPToLocation(ip) {
  /// Approach 1: through my own backend through "http://ip-api.com/json/".

  // // const ip = "2601:646:ca80:4cf0:29a3:804d:800c:5ffe";
  // const ipToLocationApiEndpoint = 'https://us-central1-myredirect-b32e0.cloudfunctions.net/locationForIp';
  // const response = await fetch(ipToLocationApiEndpoint, {
  //   method: 'POST',
  //   headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //     'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  //   },
  //   body: JSON.stringify({
  //     ip: ip
  //   })
  // });
  // const responseJson = await response.json();
  // return responseJson.address;

  /// Approach 2: through 'https://ipwho.is/'

  // const ipToLocationApiEndpoint = 'https://ipwho.is/';
  // const ip = "2601:646:ca80:4cf0:29a3:804d:800c:5ffe";
  // const requestUrl = ipToLocationApiEndpoint + ip;
  // const response = await fetch(requestUrl);
  // const responseJson = await response.json();
  // console.log(responseJson);
  // const address = "" + responseJson.city + ", " + responseJson.region_code + ", " + responseJson.country_code;
  // console.log(address);

  // return address;

  /// Approach 3: through 'https://ipapi.co/8.8.8.8/json/'. 1000 free lookups per day.

  const requestUrl = "https://ipapi.co/" + ip + "/json/";
  const response = await fetch(requestUrl);
  const responseJson = await response.json();
  console.log(responseJson);
  const address = "" + responseJson.city + ", " + responseJson.region_code + ", " + responseJson.country_code;
  return address;
}

function ShowLocation({ ip }) {
  const [location, setLocation] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    const location = await iPToLocation(ip);
    setLocation(location);
  }
  return (
    <div>
      <div>
        <button onClick={handleSubmit}> click to see approximate location</button>
      </div>
      <div>
        {location}
      </div>
    </div>
  );
}

function AllLogSection() {
  const [log, setLog] = useState([]);
  const customizeurlEndpoint = 'https://us-central1-myredirect-b32e0.cloudfunctions.net/fetchlog';

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch(customizeurlEndpoint);
    const body = await response.json();
    let itemsFetched = (body.logFetched)[0];
    console.log(itemsFetched);

    let tempLogArray = [];
    for (let i = 0; i < itemsFetched.length; i++) {

      let item = itemsFetched[i];
      let payload = item.textPayload;

      if (payload !== undefined) {
        var date = new Date(item.timestamp.seconds * 1000);
        payload += (", event_time = " + date.toLocaleString());
        tempLogArray.push(payload);
      }
    }
    setLog(tempLogArray);
  }

  return (
    <div className="container">
      <div>
        <button onClick={handleSubmit}>Click to show all logs!</button>
      </div>
      <br />

      <div className="center-col">
        <ul>
          {log.map((item) => <p>{item}</p>)}
        </ul>
      </div>

    </div>
  );

}

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <body>
        <UrlGeneration />
        <br />
        <br />
        <br />
        {/* <AllLogSection /> */}
        <FilteredLogSection />
      </body>
    </div >
  );
}

export default App;
